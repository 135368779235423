<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    style="min-width:160px; width: 160px; margin-right: 5px;"
    :value="this.selected"
    :items="countriesSorted"
    item-value="iso2"
    item-text="name"
    :label="label"
    dense
    hide-details
    :outlined="outlined"
  >
    <template v-slot:selection="{ item, selected }">
      <div class="iti-flag" :class="item.iso2.toLowerCase()"></div>
      {{ getCountryCallingCode(item.iso2) }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
  import { getCountryCallingCode } from "libphonenumber-js";

  export default {
    inheritAttrs: false,
    name: "CountrySelector",
    props: {
      value: {
        type: String,
        required: false
      },
      defaultCountry: {
        type: String,
        required: false,
        default: () => "TR"
      },
      label: String,
      disabled: { type: Boolean, default: false },
      items: { type: Array, default: Array, required: true },
      preferredCountries: { type: Array, default: null },
      onlyCountries: { type: Array, default: null },
      ignoredCountries: { type: Array, default: Array },
      errorMessages: {
        default: () => [],
        type: Array
      },
      outlined: Boolean
    },
    data() {
      return {
        dialCode: null,
        isFocus: false,
        selectedIndex: null,
        query: "",
        selected: null
      };
    },
    watch: {
      value(val) {
        this.selected = val;
      }
    },
    computed: {
      countriesList() {
        return this.items.filter(item => !this.ignoredCountries.includes(item.iso2));
      },
      countriesFiltered() {
        const countries = this.onlyCountries || this.preferredCountries;
        return this.countriesList.filter(item => countries.find(country => item.iso2.includes(country)));
      },
      otherCountries() {
        return this.countriesList.filter(item => !this.preferredCountries.includes(item.iso2));
      },
      countriesSorted() {
        return this.preferredCountries
          ? [...this.countriesFiltered, ...this.otherCountries]
          : this.onlyCountries
          ? this.countriesFiltered
          : this.countriesList;
      }
    },
    methods: {
      getCountry(code) {
        code = code && code.toUpperCase();
        let found = this.countriesList.find(item => item.iso2 === code);
        if (!found) {
          found = this.countriesList.find(item => item.iso2 === "TR");
        }
        return found;
      },
      getCountryCallingCode(v) {
        return `+${getCountryCallingCode(v)}`;
      },
      input(val) {
        this.$emit("change", val);
      }
    },
    created() {
      this.selected = this.value || this.defaultCountry;
      this.$emit("change", this.getCountry(this.selected));
    }
  };
</script>

<style scoped src="@/assets/sprite.css"></style>

<style lang="scss" scoped>
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .iti-flag {
    margin-right: 10px;
  }

  ::v-deep .v-select__selections {
    input {
      min-width: 0 !important;
    }
  }

  .country-selector {
    position: relative;

    .field-country-flag {
      margin: auto 0;
      position: absolute;
      top: 21px;
      left: 13px;
      z-index: 1;

      img {
        position: absolute;
      }
    }

    .field-label {
      position: absolute;
      top: 3px;
      cursor: pointer;
      left: 13px;
      -webkit-transform: translateY(25%);
      transform: translateY(25%);
      opacity: 0;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 11px;
      color: rgba(0, 0, 0, 0.54);
    }

    .field-input {
      cursor: pointer;
      background-color: transparent;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: relative;
      width: 100%;
      height: 42px;
      min-height: 42px;
      padding-right: 18px;
      padding-left: 10px;
      font-weight: 400;
      -webkit-appearance: none;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      font-size: 13px;
      z-index: 0;
    }

    &-arrow {
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
      width: 25px;
      color: #424242;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 15px;
      transform: scaleY(0.5);
      text-align: center;
      display: inline-block;
    }

    &.has-error {
      .field-input {
        border-color: orangered !important;
      }
    }

    &.has-value {
      .field-label {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        font-size: 11px;
      }

      .field-input {
        padding-top: 14px;
        padding-left: 40px;
      }
    }

    &.has-hint {
      .field-label {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        font-size: 11px;
      }

      .field-input {
        padding-top: 14px;
      }
    }

    &.is-focused {
      .country-selector-arrow {
        transform: scaleY(0.5) rotate(-180deg);
      }
    }

    &.is-disabled {
      .field-input {
        border-color: #ccc;
        background-color: #f2f2f2;
      }

      .field-label,
      .field-input {
        cursor: default;
      }
    }

    &.no-flags {
      .field-input {
        padding-left: 10px;
      }
    }

    .text-danger {
      color: orangered !important;
    }

    .country-list {
      padding: 0;
      list-style: none;
      background: #fff;
      height: 210px;
      max-height: 210px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 9;
      margin: 0;
      max-width: 100%;
      position: absolute;
      top: 100%;
      border-radius: 4px;
      width: 100%;
      min-width: 230px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      &-item {
        padding: 0 10px;
        height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
        cursor: pointer;

        &:hover,
        &.keyboard-selected {
          background-color: #f2f2f2;
        }

        &.selected {
          color: #fff;
          font-weight: 600;
        }

        .flag-container {
          margin-right: 10px;
        }
      }
    }

    &.is-dark {
      .field-label {
        color: rgba(255, 255, 255, 0.7);
      }

      .field-input {
        background-color: #424242;
        border-color: rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.7);
      }

      .country-list {
        background-color: #424242;
        color: rgba(255, 255, 255, 0.7);

        &-item:hover,
        &-item.keyboard-selected {
          background-color: darken(#424242, 5%);
        }
      }

      .country-selector-arrow {
        color: rgba(255, 255, 255, 0.7);
      }

      &.is-disabled {
        .field-label,
        .field-input {
          color: #000;
        }
      }

      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(255, 255, 255, 0.7);
      }

      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(255, 255, 255, 0.7);
        opacity: 1;
      }

      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(255, 255, 255, 0.7);
        opacity: 1;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.7);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(255, 255, 255, 0.7);
      }

      ::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(255, 255, 255, 0.7);
      }

      &.is-disabled {
        .field-input {
          border-color: #ccc;
          background-color: #f2f2f2;
        }

        .country-selector-arrow {
          color: #888;
        }

        ::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #424242;
        }

        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #424242;
          opacity: 1;
        }

        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #424242;
          opacity: 1;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #424242;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #424242;
        }

        ::placeholder {
          /* Most modern browsers support this now. */
          color: #424242;
        }
      }
    }

    &.sm {
      .field-input {
        height: 36px;
        min-height: 36px;
        font-size: 12px;
      }

      .field-label {
        font-size: 10px;
      }

      .field-country-flag {
        top: 16px;

        img {
          zoom: 0.3;
          -moz-transform: scale(0.3);
          -moz-transform-origin: 0 0;
        }
      }

      &.has-value {
        .field-input {
          padding-top: 12px;
        }
      }
    }

    &.lg {
      .field-input {
        height: 48px;
        min-height: 48px;
        font-size: 16px;
      }

      .field-label {
        font-size: 14px;
      }

      .field-country-flag {
        top: 25px;

        img {
          zoom: 0.45;
          -moz-transform: scale(0.45);
          -moz-transform-origin: 0 0;
        }
      }

      &.has-value {
        .field-input {
          padding-top: 18px;
        }
      }
    }

    .slide-enter-active,
    .slide-leave-active {
      opacity: 1;
      z-index: 998;
      transition: all 0.3s;
      transform: translateY(0);
    }

    .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */
 {
      opacity: 0;
      z-index: 998;
      transform: translateY(-20px);
    }
  }
</style>
